import { ErrorBoundary } from '@glow/molecule-components/error-boundary'
import i18next from 'i18next'
import React, { Suspense, useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { BrowserBannerWrapper } from './common/components/BrowserBannerWrapper'
import Loading from './common/components/Loading'
import { ThemeProvider } from './common/contexts/ThemeContext'
import { trackingPageLoaded } from './common/http/http'
import PageNotFound from './common/pages/PageNotFound'
import './i18n/i18n'
import { MetaTags } from './meta-tags'
import { bookingBaseUrl, oldBookingBaseUrl } from './norgespakke/BookingUrl'
import './styles/index.scss'
import './styles/typo.scss'
import { removeLoadingSkeleton } from './utils/removeLoadingSkeleton'

const TimeWindowPurchaseTerms = React.lazy(
  () => import('./enrichshipment/timewindowpurchase/pages/TimeWindowPurchaseTerms')
)
const TrackDeliveryRoutes = React.lazy(() => import('./trackdelivery/TrackDeliveryRoutes'))
const TrackPickup = React.lazy(() => import('./trackpickup/pages/TrackPickup'))
const EnrichShipment = React.lazy(() => import('./enrichshipment/pages/EnrichShipment'))
const UpsalesTerms = React.lazy(() => import('./common/components/service-upsales/UpsalesTerms'))
const BookingRoutes = React.lazy(() => import('./norgespakke/pages/BookingRoutes'))

function App() {
  useEffect(() => {
    removeLoadingSkeleton()
    trackingPageLoaded()
  }, [])
  return (
    <Suspense fallback={<Loading role="alert" aria-live="polite" aria-label="Loading" />}>
      <ErrorBoundary
        isRunningTests={false}
        title={i18next.t('error.title')}
        buttonText={i18next.t('error.errorClickToReload')}
      >
        <ThemeProvider>
          <HelmetProvider>
            <a href="#main-content" className="skip-to-main-content-link">
              {i18next.t('skipToMainContent')}
            </a>

            <BrowserBannerWrapper>
              <BrowserRouter basename="/track">
                <MetaTags />
                <Routes>
                  <Route path={`${bookingBaseUrl}/*`} element={<BookingRoutes />} />
                  <Route path={`${oldBookingBaseUrl}/*`} element={<BookingRoutes />} />
                  <Route path="pickup/:trackingId" element={<TrackPickup />} />
                  <Route path="delivery/:shipmentId/*">
                    <Route path="*" element={<EnrichShipment />} />

                    <Route path="time-window-terms" element={<TimeWindowPurchaseTerms />} />
                  </Route>

                  <Route path=":trackingId/*" element={<TrackDeliveryRoutes />} />

                  <Route path="upsales-terms" element={<UpsalesTerms />} />

                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </BrowserRouter>
            </BrowserBannerWrapper>
          </HelmetProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </Suspense>
  )
}

export default App
