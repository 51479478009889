const fr = {
  common: {
    unknown: 'Inconnu',
    shipmentId: "Numéro d'expédition",
    senderRef: "Référence de l'expéditeur",
    phone: 'Téléphone:',
    yes: 'Oui',
    no: 'Non',
    cancel: 'Annuler',
    somethingWentWrongTryAgain: "Quelque chose s'est mal passé. Veuillez réessayer plus tard.",
    courier: 'Courrier',
    unsupportedBrowser: 'Mettez à jour la version de votre navigateur pour accéder à toutes les fonctionnalités',
    blankText: '(vierge)',
    updatedInformation: 'Informations mises à jour',
    thanks: 'Merci!',
    edit: 'Éditer',
    required: 'obligatoire'
  },
  countries: { sweden: 'Suède', norway: 'Norvège', denmark: 'Danemark', finland: 'Finlande' },
  skipToMainContent: 'Aller au contenu principal',
  pageNotFound: {
    title: 'Page non trouvée',
    message: "Nous n'avons pas pu trouver la page que vous recherchez",
    reloadPage: 'Essayez de relancer la page'
  },
  application: {
    loading: "Chargement de l'application en cours..."
  },
  menu: {
    shipmentTracking: 'Localiser mon colis',
    aboutShipment: 'A propos de ma livraison',
    enrichShipment: 'Localiser mon colis',
    enrichAbout: 'A propos de ma livraison',
    shipmentEvents: 'Événements'
  },
  logisticsProvider: { deliveredBy: 'Livré par' },
  deliveredShipment: { goodsDelivered: 'Vos marchandises sont livrées !' },
  deviatedShipment: {
    goodsNotDelivered: 'Marchandises non récupérées',
    lastEvent: 'Dernier événement {{time}}',
    nextStep: {
      headline: 'Que se passe-t-il ensuite ?',
      newAttempt:
        "Nous essaierons de vous livrer votre colis le jour ouvrable suivant. Vous recevrez un nouveau message lorsqu'il sera chargé pour la livraison.",
      returnToSender: "Selon les instructions de l'expéditeur, le colis sera retourné à l'expéditeur.",
      pickupPoint:
        "Le colis sera livré à votre point de retrait local au cours du jour ouvrable suivant. Vous recevrez un nouveau message lorsqu'il sera prêt à être récupéré.",
      reachOut: 'Nous vous contacterons pour planifier une nouvelle tentative de livraison.'
    }
  },
  shipmentTracking: {
    airExpress: {
      collected:
        'Les colis de {{company}} ont été enlevés à {{pickupArea}} et seront envoyés en avion à {{deliveryArea}}.',
      collectedAtDeliveryLeg: 'Les colis de {{company}} ont été enlevés à {{arrivalAirportName}}.',
      collectedAtFlightLeg:
        'Les colis de {{company}} ont été acceptés au terminal de {{departureAirportName}} et seront envoyés par avion à {{deliveryArea}}.',
      collectedAtFlightLegWithOnlyFlightLeg:
        'Les colis de {{company}} ont été acceptés au terminal de {{departureAirportName}}. Les colis seront envoyés en avion à {{arrivalAirportName}} afin d’y être enlevés par le destinataire.',
      collectedAtFlightLegWithPickup:
        'Les colis de {{company}} seront envoyés en avion à {{arrivalAirportName}} afin d’y être enlevés par le destinataire.',
      collectedAtPickupLegWithPickup:
        'Les colis de {{company}} ont été enlevés à {{pickupArea}} et seront envoyés en avion à {{arrivalAirportName}} afin d’y être enlevés par le destinataire.'
    },
    map: 'Carte',
    estimatedDeliveryTime: 'Heure de livraison estimée',
    deliveryTimeChanged: 'Votre créneau de livraison estimé a changé.',
    numberOfStopsLeft_one: 'Il reste {{count}} arrêt avant vous',
    numberOfStopsLeft_other: 'Il reste {{count}} arrêts avant vous',
    manyStopsLeft: 'Plus que {{many}} arrêts avant vous',
    nextStop: 'Vous êtes le prochain arrêt!'
  },
  aboutShipment: {
    delayMessageH2Message: 'Hvis din pakke blir forsinket, så vil vi forsøke å levere igjen neste virkedag.',
    plannedDeliveryTimeHeader: 'Créneau de livraison estimé pour la commande de {{customerName}}',
    header: 'À propos de votre livraison {{customerName}}',
    deliveryTime: 'Créneau de livraison',
    timeWindow: 'entre {{timeWindow}}',
    deliveryHeader: 'Adresse de livraison et coordonnées'
  },
  shipmentEvents: { header: 'Les dernières nouvelles de votre livraison de {{customerName}}' },
  error: {
    cannotLoadMap: 'Désolé, la carte ne peut pas être chargée pour le moment.',
    invalidDeliveryDate: "L'heure sélectionnée n'est plus disponible. Veuillez recharger la page et réessayer.",
    phoneNumber: 'Numéro de téléphone invalide. Valide: +47 12345678.',
    phoneNumberPrefix: 'Vous devez préciser l’indicateur pays, p.ex. +47...'
  },
  event: {
    parcels: 'colis',
    outOf: 'sur',
    deviationHeadline: {
      'H-12': "Nous n'avons pas pu vous livrer",
      'H-10': 'Adresse erronnée',
      'H-13': 'Votre livraison va être retournée au destinataire',
      'V-41-K': 'Votre livraison est malheureusement retardée',
      'V-DB-AB': 'Votre livraison est malheureusement retardée',
      'H-11-Z': 'Adresse erronnée'
    },
    deviation: {
      'H-12': "Une tentative a été faite pour livrer la marchandise, mais le destinataire n'était pas présent",
      'H-11': 'Nous avons essayé de vous livrer, mais votre adresse était malheureusement incomplète ou erronée',
      'H-10': 'Nous avons essayé de vous livrer, mais votre adresse était malheureusement incomplète ou erronée',
      'H-13': "Nous n'avons malheureusement pas pu vous livrer en raison de votre refus de livraison",
      '7-10': "Les marchandises n'ont pas pu être récupérées en raison d'une adresse incomplète",
      '7-12': "L'expéditeur n'était pas disponible. Les marchandises n'ont donc pas été récupérées",
      '7-82': "Les marchandises n'étaient pas disponibles à l'enlèvement",
      'V-41-K': 'La livraison est retardée, elle sera expédiée dès que possible',
      'H-07-R': 'Échec du contrôle social',
      'H-07-Z': 'Échec du contrôle social',
      'H-08-Z': "Échec du contrôle de d'âge",
      'H-09-Z': 'Échec du contrôle d identité',
      'VI-16-X': 'Impossible de livrer dans le délai imparti',
      'V-100-CA': 'We can not deliver to a parcelbox'
    },
    message: {
      created: 'Créé',
      delivered: 'Livré',
      collected: 'Collecté',
      deviated: 'Échec',
      'delivery-time-estimated': 'Créneau mis à jour',
      'delivery-time-purchased': 'Nouveau créneau de livraison acheté',
      'delivered-manually-overridden': 'Livré',
      'deviated-manually-overridden': 'Échec',
      'collected-manually-overridden': 'Collecté',
      'flex-delivery-ordered': 'Livraison',
      flexDeliveryOrderedDescription: 'Livraison commandée par le destinataire',
      'flex-delivery-changed': 'Livraison modifiée',
      'apartment-information-updated': "Informations sur l'appartement mises à jour",
      'service-upgrade-purchased': 'Service mis à jour',
      serviceUpgradePurchasedDescription: 'La livraison a été mise à jour de « Au pied du camion » à « À l’intérieur »',
      'claim-registered-email-sent': 'Envoyer un e-mail concernant les articles endommagés ou manquants'
    }
  },
  date: { today: "Aujourd'hui", tomorrow: 'Demain', yesterday: 'Hier' },
  serviceUpsales: {
    heading: 'Souhaitez-vous que les marchandises soient livrées à l’intérieur ?',
    curbsideServiceInfo:
      'Vous avez demandé une livraison au pied du camion. En cliquant sur « Livraison à l’intérieur », vous pouvez décider de la date et l’heure auxquelles vous voulez que les marchandises soient délivrées à l’intérieur de votre domicile. Lisez nos conditions pour en savoir plus sur notre service additionnel de livraison à l’intérieur.',
    orderService: 'Livraison à l’intérieur',
    agreeToTerms: 'Passer au paiement ou sélectionner un nouveau créneau horaire de livraison.',
    noAvailableDeliveryTimesError:
      'Une erreur s’est produite lors de la récupération de créneaux horaires. Merci de recharger la page.'
  },
  housingInfo: {
    housingTypeTitle: 'Résidez-vous en appartement ou maison individuelle?',
    apartment: 'Appartement',
    house: 'Maison individuelle',
    selectConfirmationApartment:
      'Merci de nous faciliter la livraison! Assurez-vous que <strong>votre nom</strong> soit visible sur la porte ou la boîte aux lettres.',
    selectConfirmationHouse:
      'Merci de nous faciliter la livraison! Assurez-vous que <strong>votre numéro de rue</strong> soit visible sur votre maison ou votre boîte aux lettres.',
    apartmentInformation: 'Information sur l’appartement',
    gateCodeOrNameLabel: "Code du port ou nom de l'interphone",
    floorNumber: 'Numéro d’étage',
    elevator: 'Ascenseur',
    selectOption: 'Chiossissez une option',
    elevatorIsAvailable: 'Oui, un ascenseur est disponible',
    elevatorNotAvailable: 'Non, pas d’ascenseur disponible',
    submitError: 'Merci de renseigner un numéro d’étage',
    submit: 'Soumettre',
    submitChange: 'Enregistrer les changements',
    confirmationText: 'Ceci va permettre de nous faviliter la livraison.',
    confirmationHeader: "Merci d'avoir mis à jour les informations de votre appartement."
  },
  flex: {
    header: 'Devons-nous placer la livraison devant votre porte?',
    termsApproval: 'En sélectionnant Oui, vous acceptez nos <1>conditions</1>.',
    privacyPolicy: 'Vous pouvez trouver notre <1>politique de confidentialité</1> ici.',
    deliverySpotHeader: 'Où devons-nous le déposer ?',
    deliverySpotDefaultOption: 'Choisissez un endroit',
    deliverySpotTextHeader: 'Message au chauffeur (facultatif), max 50 caractères',
    deliverySpotError: 'Choisissez où nous devons placer les marchandises',
    confirmationHeader: "Merci d'avoir mis à jour vos informations de livraison.",
    confirmationText:
      'Nous placerons vos marchandises {{location}} et vous informerons lorsque la livraison est terminée. Il se peut que le chauffeur prenne une photo lors de la livraison.',
    infoText:
      'Comme convenu, nous placerons vos marchandises {{location}}. Nous vous informerons lorsque la livraison sera effectuée. Il se peut que le chauffeur prenne une photo lors de la livraison.',
    notRingDoorBellText: 'Le coursier ne sonnera pas à la porte.',
    submitChanges: 'Soumettre des changements',
    submit: 'Soumettre',
    terms: {
      header: 'Termes et conditions',
      description: "Livraison à l'extérieur de la porte - voici comment cela fonctionne :",
      details1:
        "En tant que destinataire, vous choisissez l'endroit où nous placerons les marchandises et êtes responsable de veiller à ce qu'il s'agisse d'un endroit approprié. N'oubliez pas que le lieu de livraison doit être à la fois sûr et résistant aux intempéries.",
      details2: 'Vous recevrez un SMS confirmant que la livraison a été effectuée.',
      details3: 'Après la livraison, vous, en tant que destinataire, êtes responsable de tout dommage ou perte.'
    },
    flexDeliveryCancelledInfo: 'Nous vous le remettrons en personne.'
  },
  digitalIdentity: {
    cta: {
      verify: 'Vérifiez Votre Identité',
      submit: 'Soumettre et signer',
      retry: 'Essayez de vérifier à nouveau',
      info: {
        header: 'Vous souhaitez une livraison plus fluide?',
        se: "Pour faciliter la livraison, vous pouvez vous identifier avec BankID avant l'heure de livraison",
        no: "Pour faciliter la livraison, vous pouvez vous identifier avec BankID avant l'heure de livraison",
        dk: "Pour faciliter la livraison, vous pouvez vous identifier avec MitID avant l'heure de livraison"
      },
      flex: {
        header: "Pièce d'identité requise",
        se: "Vous devez vous identifier avant que nous puissions effectuer la livraison à l'extérieur. Utilisez le bouton ci-dessus pour vérifier votre identité avec BankID.",
        no: "Vous devez vous identifier avant que nous puissions effectuer la livraison à l'extérieur. Utilisez le bouton ci-dessus pour vérifier votre identité avec BankID.",
        dk: "Vous devez vous identifier avant que nous puissions effectuer la livraison à l'extérieur. Utilisez le bouton ci-dessus pour vérifier votre identité avec MitID."
      },
      button: { se: 'Signer avec BankID', no: 'Signer avec BankID', dk: 'Signer avec MitID' }
    },
    verificationError: {
      header: 'Échec de la vérification',
      message:
        "Vous pouvez toujours recevoir votre envoi en personne en montrant une pièce d'identité valide à l'arrivée du chauffeur, ou vous pouvez essayer de compléter la vérification une fois de plus"
    },
    verificationSuccess: {
      header: 'Votre identité est vérifiée',
      saveCodeHeader: "La vérification d'identité a été effectuée avec succès!",
      saveCodeText:
        "Enregistrez ce code et apportez-le avec vous lorsque vous recevez le colis, ou envoyez-le à celui qui le récupère. Vous recevrez un SMS lorsque le coursier se trouve en dehors de l'adresse de livraison. Ce code est également envoyé sous forme de sms.",
      nextPageVisit:
        'Votre code de vérification vous a été envoyé par sms, donnez le code au chauffeur lors de la livraison.'
    },
    visibleDamageNotice:
      "Assurez-vous de vérifier les marchandises pour les dommages visibles avant d'approuver la livraison. Les dommages visibles doivent être enregistrés par le conducteur."
  },
  damageInfo: {
    reportDamage: 'Colis endommagé',
    submit: 'Soumettre',
    unfinishedReport: 'Unfinished Report',
    reportSubmittedPara1:
      'Merci d’avoir pris le temps d’enregistrer une anomalie concernant votre commande. IKEA vous contactera sous 24 heures.',
    reportSubmittedPara2: 'Pour revenir à la page de démarrage ou pour créer un autre rapport, appuyez sur Terminé.'
  },
  plannedDelivery: {
    headline: {
      delivery: 'Votre créneau de livraison pour votre commande de {{customer}} est estimé à',
      pickup: 'Votre livraison de {{customer}} est maintenant prête à être récupérée. Heure de ramassage prévue',
      return: 'Heure prévue de prise en charge de votre retour de {{customer}}'
    },
    deliveryTimeInfo: "Lorsque nous commencerons à livrer, vous obtiendrez une estimation d'heure plus précise.",
    notAGoodTime: 'Pas le bon moment ?',
    changeTime: "Changer la date et l'heure",
    newAttempt: "Si vous n'êtes pas chez vous pour recevoir le colis, nous réessayerons le jour ouvrable suivant",
    pickupPoint: "Si vous n'êtes pas chez vous pour recevoir le colis, il sera livré à votre point de retrait local",
    returnToSender: "Si vous n'êtes pas chez vous pour recevoir le colis, il sera retourné à l'expéditeur",
    delay: {
      headline: 'Votre livraison est retardée.',
      text: 'Nous essaierons de vous le livrer le jour ouvrable suivant'
    },
    notAvailable: {
      hd: {
        headline: 'Un problème semble être survenu !',
        text: 'Veuillez appeler notre centre de contact pour réserver une nouvelle heure de livraison, voir les numéros de téléphone ci-dessous.'
      },
      nonHd: {
        headline: "Malheureusement, nous n'avons pas encore d'estimation de livraison",
        text: ", mais vous recevrez un message lorsque le colis sera chargé sur la voiture pour la livraison et vous pourrez consulter à nouveau cette page ultérieurement pour plus d'informations."
      }
    }
  },
  timeBooking: {
    confirm: 'Confirmer',
    header: 'Choisissez une date et une heure',
    deliveryTime: {
      delivery: 'Créneau de livraison',
      pickup: 'Heure de prise en charge',
      return: 'Heure de prise en charge'
    },
    submit: {
      delivery: 'Soumettre le créneau de livraison',
      pickup: "Confirmer l'heure de prise en charge",
      return: "Confirmer l'heure de prise en charge"
    },
    confirmation: {
      ready: 'Vous êtes prêt !',
      details: {
        delivery: 'Détendez-vous, nous nous occupons du reste. Nous arriverons entre:',
        pickup: 'Détendez-vous, nous nous occupons du reste. Vous pouvez venir récupérer votre colis entre :',
        return: 'Détendez-vous, nous nous occupons du reste. Nous arriverons entre :'
      },
      edit: 'Changer'
    }
  },
  bookingNotPossible: {
    header: 'Réservation impossible',
    details: {
      delivery:
        "Désolé, il n'est pas possible de réserver un délai de livraison en ligne pour le moment. Veuillez réessayer plus tard ou appeler notre centre de contact à l'un des numéros de téléphone ci-dessous.",
      pickup:
        "L'heure de prise en charge ne peut pas être réservée en ligne pour le moment. Veuillez réessayer dans un instant ou appeler notre centre de contact à l'un des numéros ci-dessous.",
      return:
        "L'heure de prise en charge ne peut pas être réservée en ligne pour le moment. Veuillez réessayer dans un instant ou appeler notre centre de contact à l'un des numéros ci-dessous."
    },
    'details-saas': {
      delivery:
        "Désolé, il n'est pas possible de réserver un créneau de livraison en ligne en ce moment. Veuillez réessayer plus tard.",
      pickup:
        'Il n’est pas possible de réserver un créneau de ramassage en ligne en ce moment. Veuillez réessayer dans un instant.',
      return:
        'Il n’est pas possible de réserver un créneau de ramassage en ligne en ce moment. Veuillez réessayer dans un instant.'
    }
  },
  contact: {
    questions: 'Si vous avez des questions',
    callUs: 'vous pouvez appeler notre Contact Center.',
    contactUs: 'vous pouvez nous contacter au '
  },
  pickupTracking: {
    estimatedPickupTime: 'Heure de prise en charge estimée',
    goodsPickedUp: 'Les marchandises ont été ramassées!'
  },
  environmentFriendly: {
    fossilFreeDelivery1: 'Votre livraison est ',
    fossilFreeDelivery2: 'opérée par un véhicule dans énergie fossile .',
    zeroEmissionDelivery: 'Votre livraison est effectuée ',
    bicyclePreposition: 'au ',
    carPreposition: 'avec ',
    electric: 'un véhicule électrique.',
    bicycle: 'une bicyclette.',
    hydrogen: 'un véhicule à hydrogène.',
    bring: {
      electric: '100% electrique',
      bicycle: '100% à la force des gambettes',
      hydrogen: '100% hydrogène',
      fossilFreeDelivery: '100% carburant sans fossile',
      naturally: 'evidemment'
    }
  },
  ikeaDeliveryNote: {
    doYouNeedADeliveryNote: "Besoin d'un bon de livraison d'IKEA ?",
    noProblemDownloadItHere: 'Aucun problème! Vous pouvez le télécharger ici.',
    downloadDeliveryNote: 'Télécharger le bon de livraison',
    noProblemViewItHere: 'No problem! You can view and print it here.',
    viewDeliveryNote: 'View delivery note'
  },
  deliveryNote: {
    title: 'Delivery Note',
    sender: 'Sender',
    vatNumber: 'VAT no',
    orderNumber: 'Order number',
    receiver: 'Receiver',
    phone: 'Phone',
    shipmentId: 'Shipment ID',
    articles: 'Articles',
    orderDate: 'Order Date',
    deliveryDate: 'Delivery Date',
    deliveryTime: 'Delivery Time',
    printedDate: 'Printed Date',
    receiversReference: "Receiver's reference",
    receiversReferenceNote: 'Please use this number in all contact with us',
    articleNumber: 'Number',
    articleName: 'Name',
    articlePrice: 'Price',
    articleQuantity: 'Quantity',
    numberOfPackages: 'Number of packages',
    totalWeight: 'Total weight',
    totalVolume: 'Total volume',
    print: 'Print delivery note'
  },
  requestPhoneNumber: {
    header: 'Notifications SMS',
    help: 'Ajoutez votre numéro pour recevoir des notifications de livraison par SMS.',
    label: 'Votre numéro de téléphone portable',
    save: 'Enregistrer le numéro',
    done: 'Merci! Vous recevrez désormais des notifications de livraison par SMS.'
  },
  altText: { previewImage: "Aperçu de l'image" },
  purchaseReceipt: {
    purchaseOfTimeWindowHeading: 'Votre nouvelle heure a été commandée et est confirmée',
    serviceUpgradeHeading: 'La mise à jour de la livraison a été commandée et est confirmée',
    receipt: 'Justificatif d’achat',
    organisationNumber: 'No d’org. : 556546-4939',
    orderNumber: 'Numéro de commande',
    time: 'Heure',
    purchaseOfTimeWindow: 'Créneau horaire de livraison',
    serviceUpgrade: 'Livraison à l’intérieur',
    gross: 'Total hors TVA',
    vat: 'TVA ({{vat}} %)'
  },
  parcelLocker: { cannotCancelMessage: 'Choosing delivery to a parcel locker can not be cancelled.' }
}

export default fr
