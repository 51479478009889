import { HousingInformation, PickupPoint } from '@glow/entity-types'
import { backendLanguage } from '../../i18n/language'
import { FlexDelivery } from '../../trackdelivery/domain/FlexDelivery'
import { formatDate } from '../utils/dateUtils'
import { consignmentStandard } from './preview-responses/consignmentStandard'
import { flexDeliveryChoicesStandard } from './preview-responses/flexDeliveryChoicesStandard'
import { query } from './query'
import { sendCommand, sendCommandRaw, sendOpenCommand } from './sendCommand'
import { RecycleGoodsFormProps } from '../../trackdelivery/components/recycle-goods/utils'

export const getConsignments = async (
  trackingId: string,
  internalRequest: boolean,
  includeStopsLeft?: boolean,
  previewScenario?: string | null
) => {
  return await query(
    'consignmentByExternalId',
    {
      externalId: trackingId,
      language: backendLanguage(),
      internalRequest,
      includeStopsLeft
    },
    previewScenario ? consignmentStandard : undefined
  )
}

export const getFlexTexts = async (previewScenario?: string | null, isB2B?: boolean) =>
  await query('flexDeliveryChoices', { isB2B }, previewScenario ? flexDeliveryChoicesStandard : undefined)

export const getPickup = async (trackingId: string) => await query('pickup', { externalId: trackingId })

export const getAvailableDeliveryTimesH2 = async (trackingId: string) =>
  await query('availableDeliveryTimesH2', { externalId: trackingId })

export const addOrUpdateHousingInformation = async (
  externalId: string,
  housingInformation?: HousingInformation,
  lockCode?: string | null
) => {
  const commandType = 'ADD_OR_UPDATE_HOUSING_INFORMATION'
  await sendCommand(commandType, externalId, {
    type: commandType,
    payload: {
      id: externalId,
      housingType: housingInformation?.housingType,
      lockCode,
      floorNumber: housingInformation?.floorNumber,
      isElevatorAvailable: housingInformation?.isElevatorAvailable
    }
  })
}

export const getParcelLockers = async (externalId: string) => await query('getParcelLockers', { externalId })

export const setParcelLocker = (externalId: string, pickupPoint: PickupPoint): Promise<void> => {
  const commandType = 'SET_PARCEL_LOCKER'
  return sendOpenCommand(commandType, {
    type: commandType,
    payload: {
      externalId,
      parcelLockerId: pickupPoint.id
    }
  })
}

export const saveRecycleGoodsDetails = (
  externalId: string,
  recycleGoodsDetails: RecycleGoodsFormProps | null
): Promise<void> => {
  const commandType = 'SAVE_RECYCLE_GOODS_DETAILS'
  return sendOpenCommand(commandType, {
    type: commandType,
    payload: {
      externalId,
      recycleGoodsDetails
    }
  })
}

export const cancelDeliveryToParcelLocker = (externalId: string): Promise<void> => {
  const commandType = 'CANCEL_PARCEL_LOCKER_DELIVERY'
  return sendOpenCommand(commandType, {
    type: commandType,
    payload: { externalId }
  })
}

export const trackingPageLoaded = (): Promise<void> => {
  const commandType = 'TRACKING_PAGE_LOADED'
  return sendOpenCommand(commandType, {
    type: commandType,
    payload: {}
  })
}

export const orderOrChangeFlexDelivery = async (
  externalId: string,
  flexDelivery: FlexDelivery,
  previewScenario?: string | null
) => {
  const commandType = 'ORDER_OR_UPDATE_FLEX_DELIVERY'
  await sendCommand(
    commandType,
    externalId,
    {
      type: commandType,
      payload: {
        id: externalId,
        acceptTerms: flexDelivery.agreeToTerms,
        messageText: flexDelivery.messageText,
        textId: flexDelivery.textId
      }
    },
    !!previewScenario
  )
}

export enum TimeBookingError {
  INVALID_DELIVERY_DATE = 'INVALID_DELIVERY_DATE',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export const orderDeliveryTime = async (deliveryDate: Date, externalId: string) => {
  const commandType = 'ORDER_DELIVERY_TIME'
  await sendCommand(commandType, externalId, {
    type: commandType,
    payload: {
      externalId: externalId,
      deliveryDate: formatDate(deliveryDate, 'yyyy-MM-dd')
    }
  })
}

export const cancelFlexDelivery = async (externalId: string) => {
  const commandType = 'CANCEL_FLEX_DELIVERY'
  await sendCommand(commandType, externalId, {
    type: commandType,
    payload: {
      id: externalId
    }
  })
}

export const reportDamage = async (trackingId: string, pngBlobs: Blob[], description: string) => {
  const commandType = 'UPLOAD_RECIPIENT_DAMAGE_ATTACHMENTS'
  const formData = new FormData()
  const command = JSON.stringify({
    type: 'REPORT_DAMAGE',
    payload: {
      externalId: trackingId,
      data: { description: description }
    }
  })
  pngBlobs.forEach((pngBlob) => {
    formData.append('image', pngBlob)
  })
  formData.append('command', command)

  await sendCommandRaw(commandType, `opencommand/uploadMultiple/${trackingId}`, formData)
}

export const reportMissingArticle = async (trackingId: string, description: string) => {
  const commandType = 'REPORT_MISSING_ARTICLE'

  await sendOpenCommand(commandType, {
    type: commandType,
    payload: {
      externalId: trackingId,
      data: { description: description }
    }
  })
}

export const getDeliveryNote = async (trackingId: string) => {
  return await query('deliveryNote', {
    externalId: trackingId,
    language: backendLanguage()
  })
}
