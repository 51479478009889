const sv = {
  common: {
    unknown: 'Okänt',
    shipmentId: 'Sändningsnummer',
    senderRef: 'Avsändarreferens',
    phone: 'Telefon:',
    yes: 'Ja',
    no: 'Nej',
    cancel: 'Ångra',
    somethingWentWrongTryAgain: 'Något gick fel. Vänligen försök igen senare.',
    courier: 'Chaufför',
    unsupportedBrowser: 'Uppdatera din webbläsarversion för att få tillgång till alla funktioner',
    updatedInformation: 'Uppdaterad information',
    thanks: 'Tack!',
    edit: 'Ändra',
    confirmation: 'Bekräftelse',
    required: 'obligatoriskt'
  },
  application: {
    loading: 'Laddar, var god vänta.'
  },
  feedbackBox: {
    title: 'Vad tycker du',
    description:
      'Vi genomför för närvarande ett test där du som mottagare kan spåra dina sändningar i realtid när de är ute för leverans. Vi är intresserade av att höra din åsikt, <0>vänligen ge oss feedback via denna länk</0>.'
  },
  skipToMainContent: 'Gå till huvudinnehåll',
  countries: { sweden: 'Sverige', norway: 'Norge', denmark: 'Danmark' },
  pageNotFound: {
    title: 'Sidan kunde inte hittas',
    message: 'Vi kunde tyvärr inte hitta sidan du letade efter',
    reloadPage: 'Försök att ladda om sidan'
  },
  menu: {
    shipmentTracking: 'Var är min leverans?',
    aboutShipment: 'Om min leverans',
    enrichShipment: 'Var är min leverans?',
    enrichAbout: 'Om min leverans',
    shipmentEvents: 'Händelser'
  },
  logisticsProvider: { deliveredBy: 'Levererad av' },
  deliveredShipment: { goodsDelivered: 'Din vara är levererad!' },
  deviatedShipment: {
    goodsNotDelivered: 'Varan blev inte levererad',
    lastEvent: 'Sista händelse {{time}}',
    nextStep: {
      headline: 'Vad händer nu?',
      newAttempt:
        'Vi försöker leverera paketet hem till dig nästa arbetsdag. Du får ett nytt meddelande när redo att köras ut.',
      returnToSender: 'Enligt instruktionerna från avsändaren, så kommer paketet att skickas tillbaka till avsändaren.',
      pickupPoint:
        'Paketet skickas vidare till ditt lokala utlämningsställe under nästa arbetsdag. Du får ett nytt meddelande när det är klart att hämtas.',
      reachOut: 'Vi kommer att kontakta dig för att planera in ett nytt leveransförsök.'
    }
  },
  shipmentTracking: {
    showMapButton: 'Följ leveransen live',
    map: 'Karta',
    estimatedDeliveryTime: 'Beräknad leveranstid',
    deliveryTimeChanged: 'Din beräknade leveranstid har ändrats.',
    numberOfStopsLeft_one: '{{count}} stopp innan dig',
    numberOfStopsLeft_other: '{{count}} stopp innan dig',
    manyStopsLeft: 'Mer än {{many}} stopp innan dig',
    nextStop: 'Du är nästa på tur!'
  },
  aboutShipment: {
    plannedDeliveryTimeHeader: 'Din leverans från {{customerName}} är planerad',
    header: 'Om din leverans från {{customerName}}',
    deliveryTime: 'Leveranstidpunkt',
    timeWindow: 'mellan {{timeWindow}}',
    deliveryHeader: 'Leveransadress och kontaktuppgifter'
  },
  shipmentEvents: { header: 'Det senaste om din leverans från {{customerName}}' },
  imageAlt: {
    calendarClockIcon: 'Kalenderklocka',
    questionMarkIcon: 'Frågetecken',
    checkIcon: 'Bock',
    thumbsUp: 'Tummen upp',
    sadComputer: 'Ledsen dator',
    goodsOutsideDoor: 'Vara utanför dörr',
    image: 'Bild'
  },
  error: {
    cannotLoadMap: 'Kartan kan inte laddas just nu, ledsen.',
    invalidDeliveryDate: 'Den valda tiden är inte längre tillgänglig. Vänligen ladda om sidan och försök igen.',
    title: 'Opps, något gick fel!',
    errorClickToReload: 'Klicka här för att ladda om sidan',
    phoneNumber: 'Ej giltigt telefonnummer! Exempel på giltigt: +47 12345678.',
    phoneNumberPrefix: 'Du måste skriva in landskod t.ex. +47, +46 etc.'
  },
  event: {
    parcels: 'kollin',
    outOf: 'av',
    deviationHeadline: {
      'H-12': 'Vi kunde inte leverera din sändning',
      'H-10': 'Vi kunde inte hitta din adress',
      'H-13': 'Sändningen kommer att returneras',
      'V-41-K': 'Leveransen är tyvärr försenad',
      'V-DB-AB': 'Leveransen är tyvärr försenad',
      'H-BL-A': 'Vi kunde inte komma åt den överenskomna leveransplatsen',
      'H-11-Z': 'Vi kunde inte hitta din adress',
      'VI-16-X': 'Vi lyckades inte leverera din sändning'
    },
    deviation: {
      'H-12': 'Tyvärr mötte vi ingen hos dig som kunde ta emot den',
      'H-11': 'Vi har försökt att leverera men fann inte mottagaren på adressen',
      'H-10': 'Vi kunde inte leverera din sändning eftersom vi saknade information om din adress',
      'H-13': 'För att du inte ville ta emot den',
      '7-10': 'Vi har försökt att hämta upp godset, men fann inte avsändaren på adressen',
      '7-12': 'Vi har försökt att hämta upp godset, men avsändaren var inte tillgänglig',
      '7-82': 'Vi har försökt att hämta upp, men godset var inte redo för upphämtning',
      'V-41-K': 'Den kommer att skickas vidare så snart som möjligt',
      'V-DB-AB': 'Den kommer att skickas vidare så snart som möjligt',
      'H-07-R': 'Misslyckad social kontroll',
      'H-07-Z': 'Misslyckad social kontroll',
      'H-08-Z': 'Misslyckad ålderskontroll',
      'H-09-Z': 'Misslyckad ID kontroll',
      'VI-16-X':
        'Idag gick inte allt som det skulle och vi lyckades därför inte leverera din sändning enligt överenskommelse. Vi beklagar förseningen',
      'H-BL-A': 'Därför kunde vi tyvärr inte leverera sändningen till den plats som du valt',
      'H-11-Z': 'En typisk orsak till detta är att namn eller nummer saknades vid dörren'
    },
    message: {
      created: 'Skapad',
      delivered: 'Levererade',
      collected: 'Upphämtade',
      deviated: 'Avvik',
      'delivery-time-estimated': 'Uppdaterad leveranstid',
      'delivery-time-purchased': 'Ny leveranstid köpt',
      'delivered-manually-overridden': 'Levererade',
      'deviated-manually-overridden': 'Avvik',
      'collected-manually-overridden': 'Upphämtade',
      'flex-delivery-ordered': 'Förenklad leverans',
      flexDeliveryOrderedDescription: 'Förenklad leverans beställd av mottagare',
      'flex-delivery-ordered-by-sender': 'Förenklad leverans beställd av avsändare',
      'flex-delivery-changed': 'Förenklad leverans ändrad',
      'apartment-information-updated': 'Lägenhetsinformation uppdaterad',
      'claim-registered-email-sent': 'E-post om skadade- eller saknade artiklar sänt'
    }
  },
  date: { date: 'Datum', today: 'Idag', tomorrow: 'I morgon', yesterday: 'Igår' },
  serviceUpsales: {
    heading: 'Önskar du få varorna inburna?',
    selectDateTime: 'Välj dag och tid för inbärning',
    confirm: 'Till betalning',
    curbsideServiceInfo: 'Med inbärning får du varorna levererade till en valfri plats i hemmet.',
    orderService: 'Välj tid för inbärning (från 399,-)',
    agreeToTerms: 'Genom att slutföra beställningen accepterar du våra <0>vilkor för inbärning</0>.',
    noAvailableDeliveryTimesError: 'Något gick fel när tidsfönstren skulle hämtas. Vänligen ladda om sidan.',
    termsLink: 'Se <0>villkor för inbärning</0>.',
    terms: {
      mainTitle: 'Beskrivning av tilläggstjänsten Indoor',
      mainIntroPara1:
        'Genom att beställa tilläggstjänsten inbärning så kommer våra chaufförer att bära in varan i ditt hem till den plats du själv valt och utan att du behöver hjälpa till.',
      mainIntroPara2:
        'Av säkerhetsskäl kommer våra chaufförer att ha skor på sig samt bära in varan utan att ta av förpackningen. Det är därför viktigt att du skyddar golv samt vid behov flyttar undan möbler etc. så det inte är några hinder i vägen till den plats du önskar få varan levererad.',
      mainIntroPara3:
        'Om varan är för stor för att kunna bäras in på ett säkert sätt kan våra chaufförer komma att behöva avbryta inbärningen.',
      conditionsTitle: 'Villkor tilläggstjänst Indoor',
      conditionsIntro:
        'Vid beställning av tilläggstjänsten inbärning ("<strong>Indoor</strong>") av en trottoarleverans ("<strong>Curbside</strong>") så ingår du som beställare ett avtal med Bring E-commerce & Logistics AB, org nr 556546-4939 ("<strong>Bring</strong>") och accepterar följande villkor:',
      bulletOne:
        'Beställning innebär att Bring ändrar leveransen till att omfatta tjänsten Indoor och att du ger Bring rätt att hantera nödvändiga personuppgifter i samband därmed. För mer information om hur vi hanterar personuppgifter, se <0>https://www.bring.se/privacy-policy</0>',
      bulletTwo:
        'Betalning ska ske via Swish eller med bankkort. Transaktionen mellan dig som beställare och Bring hanteras via tredjepart.',
      bulletThree: 'Lagd beställning är bindande och kan inte ångras.',
      bulletFour:
        'Genom betalning anses du som beställare ha accepterat dessa villkor. Dessa villkor accepteras därmed vid betalningstidpunkt.',
      delivery: {
        title: 'Leveransutförande',
        intro:
          'Hela sändningen, dvs. alla delar (”<strong>kollin</strong>”) i sändningen bärs av Bring in till en (1st) av beställaren anvisad plats; dvs till en och samma plats för hela sändningen.',
        bulletOne: 'Beställaren ansvarar för att förbereda och säkra fri väg till vald plats.',
        bulletTwo: 'På grund av arbetsmiljöregler kommer Brings personal att behålla skorna på under hela leveransen.',
        bulletThree: 'Beställaren ansvarar själv för att skydda inventarier, golv och mattor mot skador.',
        bulletFour:
          'Beställaren av tjänsten skall hålla husdjur (hund, katt och andra djur) i koppel eller bur och på tryggt avstånd från våra chaufförer.',
        bulletFive:
          'Bring förbehåller sig rätten att avgöra om leveransen kan ske säkert och utan risk för skada på person, gods eller egendom.'
      },
      deviation: {
        title: 'Avvikelser och begränsningar',
        intro: 'Bring förbehåller sig rätten att avbryta inbärningen om villkor inte uppfylls;',
        bulletOne:
          'fri väg enligt Brings bedömning inte föreligger till anvisad plats, t.ex. om hiss, trapphus eller andra utrymmen av Bring bedöms vara för trånga för att kunna utföra inbärningen.',
        bulletTwo:
          'sändningens beskaffenhet (storlek, form etc.) av Bring bedöms kräva mer än två personer för att kunna bäras in utan risk för person- eller egendomsskada.',
        paraOne:
          'Om inbärningen måste avbrytas kommer leverans av sändningen i stället utföras som tjänsten Curbside, dvs. fram till trottoar eller tomtgräns, utan inbärning.',
        paraTwo:
          'Notera att tilläggstjänsten inte omfattar kollin överstigande viss vikt och storlek (vikt per kolli får vara maximalt 70 kg, längd maximalt 240 cm och längd + omkrets maximalt 640 cm).',
        paraThree:
          'Chauffören har på grund av skaderisk inte möjlighet att ta ut produkter ur sin förpackning/emballage före inbärning. Chauffören har ej heller möjlighet att ta med sig förpackningen/emballaget från levererad produkt, om inte sådan tilläggstjänst köpts.'
      },
      miscellaneous: {
        title: 'Övrigt',
        paraOne:
          'Bestämmelserna i NSAB 2015 gäller vid dröjsmål, skada, force majeure m.m., se <0>bring.se/villkor</0>.',
        paraTwo:
          'För eventuella frågor vänligen kontakta Brings kundservice på tel 08 120 245 24 eller via email till <0>operationsteam.sverige@bring.com</0>.'
      }
    }
  },
  housingInfo: {
    housingTypeTitle: 'Bor du i lägenhet eller hus?',
    apartment: 'Lägenhet',
    house: 'Hus',
    selectConfirmationApartment:
      'Tack för att du hjälper oss att underlätta leveransen. Se till att <strong>ditt namn</strong> syns på dörren/brevlådan.',
    selectConfirmationHouse:
      'Tack för att du hjälper oss att underlätta leveransen. Se till att <strong>ditt husnummer</strong> syns på dörren/brevlådan.',
    apartmentInformation: 'Lägenhetsinformation',
    gateCodeOrNameLabel: 'Portkod eller namn på porttelefonen',
    floorNumber: 'Våningsplan',
    elevator: 'Hiss',
    selectOption: 'Välj alternativ',
    elevatorIsAvailable: 'Ja, hiss finns tillgängligt',
    elevatorNotAvailable: 'Nej, hiss finns inte tillgängligt',
    submitError: 'Var vänlig fyll i våningsplan',
    submit: 'Skicka in',
    submitChange: 'Skicka in Ändringar',
    confirmationText: 'Det här kommer att underlätta leveransen.',
    confirmationHeader: 'Tack för att du uppdaterade din lägenhetsinformation'
  },
  flex: {
    header: 'Ska vi ställa leveransen utanför din dörr?',
    termsApproval: 'Om du väljer ja godkänner du våra <1>villkor</1>.',
    privacyPolicy: 'Du hittar vår <1>integritetspolicy</1> här.',
    deliverySpotHeader: 'Var ska vi ställa leveransen?',
    deliverySpotDefaultOption: 'Välj plats',
    deliverySpotTextHeader: 'Meddelande till chauffören (max 50 tecken)',
    deliverySpotError: 'Välj var vi ska ställa leveransen',
    confirmationHeader: 'Tack för att du uppdaterade leveransinformationen.',
    confirmationText:
      'Enligt ditt önskemål levererar vi varan {{location}} och meddelar dig när leveransen har utförts. Chauffören kan komma att ta ett foto vid leverans.',
    infoText:
      'Enligt ditt önskemål levererar vi varan {{location}}. Vi meddelar dig när leveransen har utförts. Chauffören kan komma att ta ett foto vid leverans.',
    notRingDoorBellText: 'Chauffören ringer inte på.',
    submitChanges: 'Skicka in ändringar',
    submit: 'Skicka in',
    terms: {
      header: 'Villkor',
      description: 'Leverans utanför dörren - så fungerar det:',
      details1:
        'Som mottagare väljer du var vi ska lämna varan och ansvarar för att säkerställa att det är en lämplig plats. Kom ihåg att leveransplatsen måste vara både säker och väderskyddad.',
      details2: 'Du får ett SMS som bekräftar att varan har levererats.',
      details3: 'Efter leverans är du som mottagare ansvarig för eventuella skador eller förluster.'
    },
    flexDeliveryCancelledInfo: 'Vi kommer lämna det personligen till dig.'
  },
  digitalIdentity: {
    cta: {
      verify: 'Bekräfta din identitet',
      submit: 'Skicka in och signera',
      retry: 'Försök verifiera igen',
      info: {
        header: 'Vill du ha en smidigare leverans?',
        se: 'För att göra leveransen smidigare kan du identifiera dig med BankID före leveranstillfället',
        no: 'För att göra leveransen smidigare kan du identifiera dig med BankID före leveranstillfället',
        dk: 'För att göra leveransen smidigare kan du identifiera dig med MitID före leveranstillfället'
      },
      flex: {
        header: 'Identifiering krävs',
        se: 'Du måste identifiera dig innan vi kan ställa leveransen utanför din dörr. Använd knappen ovan för att identifiera dig med BankId.',
        no: 'Du måste identifiera dig innan vi kan ställa leveransen utanför din dörr. Använd knappen ovan för att identifiera dig med BankId.',
        dk: 'Du måste identifiera dig innan vi kan ställa leveransen utanför din dörr. Använd knappen ovan för att identifiera dig med MitID.'
      },
      button: { se: 'Signera med BankID', no: 'Signera med BankID', dk: 'Signera med MitID' }
    },
    verificationError: {
      header: 'Verifieringen kunde inte genomföras',
      message:
        'Du kan alltid ta emot din sändning personligen genom att visa giltig legitimationshandling när chauffören kommer, men du kan också testa att genomföra verifieringen ännu en gång'
    },
    verificationSuccess: {
      header: 'Din identitet är verifierad',
      saveCodeHeader: 'Din identitet är verifierad!',
      saveCodeText:
        'Spara denna kod och uppge den vid leveranstillfället, eller skicka den till personen som skall ta emot försändelsen. Du kommer att få ett SMS när chauffören är utanför leveransplatsen. Denna kod skickas också till dig via SMS.',
      nextPageVisit: 'Din verifieringskod har skickats till dig via sms, ge koden till föraren vid leverans.'
    },
    visibleDamageNotice:
      'Se till att kontrollera varorna för synliga skador innan du godkänner leveransen. Synliga skador ska registreras av föraren.'
  },
  damageInfo: {
    reportDamage: 'Rapportera skada',
    submit: 'Skicka in',
    unfinishedReport: 'Unfinished Report',
    reportSubmittedPara1:
      'Tack för att du tog dig tid att registrera ett avvik på din order. IKEA kommer att kontakta dig inom 24 timmar.',
    reportSubmittedPara2: 'Tryck klar för att gå tillbaka till startsidan, eller för att registrera ett nytt ärende.'
  },
  plannedDelivery: {
    headline: {
      delivery: 'Planerad tid för din leverans från {{customer}}',
      pickup: 'Din leverans från {{customer}} är nu klar för upphämtning. Planerad tid för upphämtning',
      return: 'Planerad tid för upphämtning av din retur till {{customer}}'
    },
    deliveryTimeInfo: 'När vi börjar leverera kommer du att få ett mer exakt estimat på en timme.',
    notAGoodTime: 'Passar inte tiden?',
    changeTime: 'Ändra datum och tid',
    newAttempt: 'Om du inte är hemma för att ta emot paketet försöker vi igen nästa arbetsdag',
    pickupPoint: 'Om du inte är hemma för att ta emot paketet levererar vi det till ditt lokala utlämningsställe',
    returnToSender: 'Om du inte är hemma för att ta emot paketet returneras det till avsändaren',
    notAvailable: {
      hd: {
        headline: 'Något verkar tyvärr ha gått snett!',
        text: 'Vänligen försök gå in på länken igen inom några minuter. Om det fortsatt inte fungerar vänligen ta kontakt med Contact Center för att boka din tid. Se telefonnummer nedan.'
      }
    }
  },
  timeBooking: {
    confirm: 'Bekräfta',
    selectDeliveryTime: 'Välj en leveranstid',
    header: 'Välj datum och tid',
    deliveryTime: { delivery: 'Leveranstid', pickup: 'Upphämtningstid', return: 'Upphämtningstid' },
    submit: {
      delivery: 'Bekräfta leveranstid',
      pickup: 'Bekräfta upphämtningstid',
      return: 'Bekräfta upphämtningstid'
    },
    confirmation: {
      header: 'Bekräftelse',
      ready: 'Nu är du klar!',
      details: {
        delivery: 'Luta dig tillbaka – så tar vi hand om resten. Vi kommer till dig:',
        pickup: 'Luta dig tillbaka – så tar vi hand om resten. Du är välkommen till oss:',
        return: 'Luta dig tillbaka – så tar vi hand om resten. Vi kommer till dig:'
      },
      edit: 'Ändra'
    }
  },
  bookingNotPossible: {
    header: 'Bokning är inte möjlig',
    details: {
      delivery:
        'Det går inte att boka leveranstid via nätet just nu. Prova igen om en stund eller ring vårt Contact Center på något av nedan nummer.',
      pickup:
        'Det går inte att boka upphämtningstid via nätet just nu. Prova igen om en stund eller ring vårt Contact Center på något av nedan nummer.',
      return:
        'Det går inte att boka upphämtningstid via nätet just nu. Prova igen om en stund eller ring vårt Contact Center på något av nedan nummer.'
    },
    'details-saas': {
      delivery: 'Tyvärr går det inte att boka en leveranstid online just nu. Var vänlig försök igen senare.',
      pickup: 'Upphämtningstid kan inte bokas online just nu. Var vänlig försök igen om en stund.',
      return: 'Upphämtningstid kan inte bokas online just nu. Var vänlig försök igen om en stund.'
    }
  },
  contact: { questions: 'Har du frågor', callUs: 'så kan du ringa vårt Contact Center.' },
  pickupTracking: { estimatedPickupTime: 'Beräknad upphämtningstid', goodsPickedUp: 'Godset har hämtats upp!' },
  environmentFriendly: {
    fossilFreeDelivery1: 'Din leverans är ',
    fossilFreeDelivery2: 'fossilfri.',
    zeroEmissionDelivery: 'Din leverans sker ',
    bicyclePreposition: 'med ',
    carPreposition: 'med ',
    electric: 'elbil.',
    bicycle: 'cykel.',
    hydrogen: 'vätgasbil.',
    bring: {
      electric: '100% elektrisk',
      bicycle: '100% muskelkraft',
      hydrogen: '100% vätgas',
      fossilFreeDelivery: '100% fossilfritt drivmedel',
      naturally: 'naturligtvis'
    }
  },
  ikeaDeliveryNote: {
    doYouNeedADeliveryNote: 'Behöver du en följesedel från IKEA?',
    noProblemDownloadItHere: 'Inga problem! Du kan ladda hem den här.',
    downloadDeliveryNote: 'Ladda hem följesedel',
    noProblemViewItHere: 'Inga problem! Du kan se och skriva ut den här.',
    viewDeliveryNote: 'Visa följesedel'
  },
  deliveryNote: {
    title: 'Följesedel',
    sender: 'Avsändare',
    vatNumber: 'Momsnummer',
    orderNumber: 'Ordernummer',
    receiver: 'Mottagare',
    phone: 'Telefon',
    shipmentId: 'Sändningsnummer',
    articles: 'Artiklar',
    orderDate: 'Beställningsdatum',
    deliveryDate: 'Leveransdatum',
    deliveryTime: 'Leveranstid',
    printedDate: 'Utskriftsdatum',
    receiversReference: 'Mottagarens referens',
    receiversReferenceNote: 'Använd detta nummer vid all kontakt med oss',
    articleNumber: 'Nummer',
    articleName: 'Namn',
    articlePrice: 'Styckpris',
    articleQuantity: 'Antal',
    numberOfPackages: 'Antal paket',
    totalWeight: 'Total vikt',
    totalVolume: 'Total volym',
    print: 'Skriv ut följesedel'
  },
  timewindowpurchase: {
    selectEarliest: 'Välj tidigaste tidpunkten',
    selectLatest: 'Välj senaste tidpunkten',
    earliest: 'Tidigast',
    latest: 'Senast',
    headline: 'Vill du ha en mer exakt leveranstid?',
    description: 'Välj en leveranstid som passar dig. Priset anpassas ju mer exakt leveranstid du väljer.',
    toPayment: 'Till betalning',
    approve: 'Jag godkänner',
    termsLink: 'villkoren',
    invalidTimeWindow: 'Detta är din nuvarande leveranstid, vänligen välj ditt nya tidsfönster innan betalning.'
  },
  vasDescription: { header: 'Om din leverans' },
  christmas: {
    title: 'Sprid lite värme i vardagen',
    description: 'Vi levererar det gärna till närmaste paketbox, paketombud eller direkt till din dörr.',
    thankYou: 'Tusen tack!',
    wishRegistered: 'Din önskan är registrerad på önskelistan'
  },
  altText: { previewImage: 'Förhandsgranska bild' },
  purchaseReceipt: {
    purchaseOfTimeWindowHeading: 'Din nya leveranstid är beställd och bekräftad',
    serviceUpgradeHeading: 'Uppdatering av leverans är beställd och bekräftad',
    receipt: 'Kvitto',
    orderNumber: 'Ordernummer',
    date: 'Datum',
    time: 'Tid',
    service: 'Tjänst',
    purchaseOfTimeWindow: 'Tidsfönster för leverans',
    serviceUpgrade: 'Inbärning av gods',
    gross: 'Totalt exkl. moms',
    vat: 'Moms ({{vat}}%)',
    total: 'Totalt'
  },
  parcelLocker: { cannotCancelMessage: 'Choosing delivery to a parcel locker can not be cancelled.' },
  cancelDeliveryTime: {
    cancelButton: 'Avboka Tidsfönster',
    preCancellationInfoText:
      'Om det här tidsfönstret inte passar dig så kan du avboka leveranstiden. Vi återkommer till dig med en ny leveranstid.',
    postCancellationConfirmationText:
      'Du avbokade det föreslagna tidsfönstret. Luta dig tillbaka så återkommer vi med ett nytt tidsfönster. '
  },
  pickupPoint: {
    heading: 'Upphämtningsaddress',
    termsLink: '<0>Se öppettider</0>'
  },
  recycle: {
    section: {
      registeredReturn: 'Registrerade returer',
      description: 'Vi har tagit emot din beställning av retur av avfall. Följade produkter är registrerade för retur:',
      withRefrigerant: '{{count}} x med kylämne',
      withoutRefrigerant: '{{count}} x utan kylämne',
      nothingToReturn: 'Du har valt att inte returnera några produkter'
    },
    landingPage: {
      heading: 'Hej {{recipientName}}!',
      para1: 'Vi har fått ett återvinningsuppdrag från {{senderName}}.',
      para2:
        'För att återtaget ska ske så smidigt som möjligt ber vi dig fylla i detta formulär som är ett lagkrav enligt Avfallsförordningen (2020:614)',
      para3:
        'Dina uppgifter sparas och skickas till Naturvårdsverket samt till {{senderName}}. Här kan du läsa mer om vår <0>integritetspolicy</0>.',
      para4: 'Det tar ca 2 minuter att fylla i formuläret, därefter kan du boka tid för leverans och återtag.',
      goToForm: 'Gå till formulär',
      nothingToRecycle: 'Jag har inget att återvinna'
    },
    register: {
      goBack: 'Gå tillbaka',
      nextStep: 'Nästa steg',
      heading: 'Registrera avfall',
      largeFridgeFreezer: 'Hög kyl eller frys',
      smallFridgeFreezer: 'Låg kyl eller frys',
      oven: 'Spis',
      dishwasher: 'Diskmaskin',
      washingMachine: 'Tvättmaskin',
      dryer: 'Torktumlare',
      tv: 'TV-apparat',
      otherElectronics: 'Övrig elektronik',
      modal: {
        add: 'Lägg till',
        update: 'Uppdatera',
        description: {
          largeFridgeFreezer:
            'Du håller på att returnera en hög kyl eller frys. Klicka på Lägg till för att fortsätta.',
          smallFridgeFreezer:
            'Du håller på att returnera en låg kyl eller frys. Klicka på Lägg till för att fortsätta.',
          oven: 'Du håller på att returnera en spis. Klicka på Lägg till för att fortsätta.',
          dishwasher: 'Du håller på att returnera en diskmaskin. Klicka på Lägg till för att fortsätta.',
          washingMachine: 'Du håller på att returnera en tvättmaskin. Klicka på Lägg till för att fortsätta.',
          dryer: 'Du håller på att returnera en torktumlare. Klicka på Lägg till för att fortsätta.',
          tv: 'Du håller på att returnera en TV-apparat. Klicka på Lägg till för att fortsätta.',
          otherElectronics: 'Du håller på att returnera övrig elektonik. Berätta mer om produkten du vill returnera.'
        },
        productContainRefrigerant: 'Innehåller produkten kylämnen?',
        productContainRefrigerantTooltip:
          'Kylämne är när produkten innehåller ett kylaggregat t.ex, luftkonditionering.',
        productWeightPlaceholder: 'Vad väger produkten?',
        registerMoreProducts: 'Registrera flera produkter',
        enterApproximatedWeight: 'Ange ungefärlig vikt (kg)',
        weightNotInRange: 'Vikten måste vara mellan {{from}}kg och {{to}}kg',
        changeAmount: 'Ändra antalet',
        deleteItem: 'Ta bort objekt',
        increaseAmount: 'Öka antal',
        decreaseAmount: 'Minska antal'
      },
      maximumItemsLimitReached: 'Maxgränsen på {{count}} produkter har uppnåtts.'
    },
    summary: {
      heading: 'Summering',
      para1: 'Snart klar! Nu har vi de uppgifter vi behöver. Kontrollera uppgifterna nedan och skicka in.',
      productsToRecycle: 'Typ av produkt jag vill återvinna',
      para2:
        'Genom att gå vidare accepterar du våra villkor för tjänsten. Tänk på att de varor du registrerat behöver vara fullt urkopplade och redo för återtag när vi anländer.',
      para3: 'Följ länken om du önskar <0>hjälp med installation och avinstallation.</0>',
      acceptTermsDescription: 'Jag godkänner villkoren och bekräftar att informationen jag fyllt i stämmer.',
      goBack: 'Gå tillbaka',
      send: 'Skicka in'
    }
  }
}

export default sv
