import { getApiPath } from './api'

const is4xxStatus = (status: number) => status.toString().startsWith('4')

const apiPath = getApiPath()

export function query(query: string, params: any, mockRequestResponse?: any): Promise<any | Response> {
  if (mockRequestResponse) {
    return new Promise((resolve) => resolve(mockRequestResponse))
  }
  return fetch(`${apiPath}/openquery/${query}`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      query: query,
      params: params
    })
  })
    .then((r) => {
      if (r.ok) {
        return r.json()
      } else if (is4xxStatus(r.status)) {
        // TODO: this should not resolve the promise, then it's impossible to handle http errors in the components
        return Promise.resolve()
      } else {
        return Promise.reject(r)
      }
    })
    .catch((error) => Promise.reject(error))
}
